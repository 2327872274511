<template>
    <div class="table w-full">
        <div class="hidden xl:flex flex-col w-full rounded-[16px]">
            <div :class="{'grid-cols-2fr-1fr-2fr-1fr-1fr-1fr': viewMode, 'grid-cols-2fr-1fr-2fr-1fr': !viewMode}" class="inter summary-header grid gap-[20px] w-full h-[49px] p-[16px] bg-[#172B37] text-[#F4F4F4] rounded-t-[16px]">
                <p>Položka</p>
                <p>Rozmer (mm)</p>
                <p>Popis</p>
                <p>Množstvo (ks)</p>
                <p v-if="viewMode">Cena (bez DPH)</p>
                <p v-if="viewMode">Cena (s DPH)</p>
            </div>
            <div class="flex flex-col w-full border-solid border-x-[1px] border-b-[1px] border-[#172b3733] rounded-b-[16px] overflow-hidden">
                <A-summary-item-desktop
                    v-for="item in showItems"
                    @deleteItem="deleteItem(item)"
                    :key="item.id"
                    :item="item"
                    :showDeleteIcon="items.length > 1"
                    :viewMode="viewMode"
                />
            </div>
            <button v-if="!viewMode" @click="addItem()" class="rounded-full flex p-[16px] gap-[12px] w-fit border-[1px] border-solid border-[#172b3733] mt-[14px] self-end">
                <span class="text-[16px] font-[600] leading-[1]">PRIDAŤ POLOŽKU</span>
                <img :src="PlusIcon" alt="plus-icon">
            </button>
        </div>
        <div class="flex xl:hidden flex-col border-t-[1px] border-l-[1px] border-r-[1px] border-solid border-[#172b3733]">
            <A-summary-item-mobile
                v-for="item in showItems"
                @deleteItem="deleteItem(item)"
                :key="item.id"
                :item="item"
                :showDeleteIcon="items.length > 1"
                :viewMode="viewMode"
            />
        </div>
    </div>
</template>

<script>
import ASummaryItemDesktop from './_components/a-summary-item-desktop.vue'
import ASummaryItemMobile from './_components/a-summary-item-mobile.vue'

import PlusIcon from '../../_assets/plus-icon.svg'

export default {
    inject: ['store', '$emitter'],

    components: {
        ASummaryItemDesktop,
        ASummaryItemMobile
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
        viewMode: {
            type: Boolean,
            default: false
        },
        offerItems: {
            type: Array,
        }
    },

    data() {
        return {
            PlusIcon
        }
    },

    computed: {
        showItems() {
            return this.items.length ? this.items : this.offerItems
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item)
        },

        addItem() {
            this.$emit('addItem')
        }
    }
}
</script>

<style lang="sass" scoped>
.summary-header
    p
        position: relative
        bottom: 3px

.secondary-button
    background: transparent
    border: 1px solid #527E99
    color: #527E99

</style>