<template>
    <div class="card flex gap-[32px] w-full md:w-[508px] h-[184px] rounded-[16px] border-[1px] border-solid border-[#071d491a]">
        <div class="h-full w-[184px]">
            <img v-if="option?.cover_image" :src="option?.cover_image?.data.publicPath" alt="option-image" class="rounded-[16px] h-full w-auto">
            <div v-else class="skelet h-full w-full rounded-[16px]"></div>
        </div>
        <div class="w-full flex flex-col justify-center items-start gap-[10px] flex-1 pr-[16px]">
            <h3 v-if="option" class="text-[#172b37e6] font-[600] text-[14px] md:text-[16px] leading-[130%]">{{ option?.title }}</h3>
            <div v-else class="skelet w-[70%] h-[25px] rounded-[5px]"></div>
            <p v-if="option" class="inter text-[#172b3780] font-[500] text-[12px] md:text-[16px] leading-[120%]">{{ option?.description }}</p>
            <div v-else class="skelet w-[85%] h-[40px] rounded-[5px]"></div>
            <A-number-input v-if="option?.type === 'number'" :value="value" @input="emitValue" />
            <A-toggle-input v-else-if="option?.type === 'toggle'" :value="value" @input="emitValue" />
            <div v-else-if="!option" class="skelet h-[32px] w-[71px] rounded-[70px]"></div>
        </div>
    </div>
</template>

<script>
import ANumberInput from './_components/a-number-input.vue'
import AToggleInput from './_components/a-toggle-input.vue'

export default {
    inject: ['store', '$emitter'],

    components: {
        ANumberInput,
        AToggleInput
    },
    
    props: {
        option: {
            type: Object,
        },
        value: {}
    },

    methods: {
        emitValue(value) {
            this.$emit('input', { option: this.option, value })
        }
    },

    
}
</script>