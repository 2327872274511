class Store {
	constructor() {
		return
	}

	state = {
		items: JSON.parse(localStorage.getItem('zoskla_items')) || [],
		steps: JSON.parse(localStorage.getItem('zoskla_steps')) || [],
		currentStepIndex: parseInt(localStorage.getItem('zoskla_currentStepIndex')) || 0,
		editing: JSON.parse(localStorage.getItem('zoskla_editing')) || false,
		location: JSON.parse(localStorage.getItem('zoskla_location')) || {},
	}

	mutations = {
		setValue(state, payload) {
			for(let key in payload) {
				state[key] = payload[key]
				localStorage.setItem(`zoskla_${key}`, JSON.stringify(payload[key]))
			}
		},

		setSteps(state, value) {
			state.steps = value
			localStorage.setItem('zoskla_steps', JSON.stringify(value))
		},
		
		setCurrentStepIndex(state, value) {
			state.currentStepIndex = value
			localStorage.setItem('zoskla_currentStepIndex', value)
		}
	}

	actions = {
		submitItem({ state, commit }) {
			const steps = state.steps
	
			// item name
			const variant = steps[1].value
			const name = variant.title
			
			// item sizes
			const sizeOptions = steps[2].items || []
			let size = ''
			for (let option of sizeOptions) {
				size = size.concat(`${option.value}x`)
			}
			size = size.substring(0, size.length - 1)
	
			// item description
			const descriptionSteps = [...steps].splice(3, steps.length - 3 - 1)
			
			let description = ''
			for (let step of descriptionSteps) {
				if (step.screen_type.startsWith('select')) 
					description = description.concat(`${step.summary_desc_text} ${step.value.title}, `)
				else if (step.screen_type.startsWith('input')) {
					const inputOptions = step.items
					let string = ''
					for (let option of inputOptions) {
						string = string.concat(`${option.value}x`)
					}
					string = string.substring(0, string.length - 1)
					description = description.concat(`${string}, `)
				}
				else if (step.screen_type.startsWith('options')) {
					const options = step.items
					let string = ''
					for (let option of options) {
						let value = option.value
						if (value === true) value = "Áno"
						else if (value === false) value = "Nie"
						string = string.concat(`${option.title}: ${value}, `)
					}
					string = string.substring(0, string.length - 2)
					description = description.concat(`${string}, `)
				}
			}
			description = description.substring(0, description.length - 2)

			const item = {
				name,
				size,
				description,
				quantity: 1,
				steps: JSON.parse(JSON.stringify(state.steps)),
				id: Date.now()
			}
	
			if (state.editing) state.items.pop()

			commit('setValue', { items: [...state.items, item] })
		}
	}

	getters = {
		getItems: () => [...this.state.items],
		getSteps: () => [...this.state.steps],
		getCurrentStepIndex: () => this.state.currentStepIndex,
		getLocation: () => this.state.location,
	}

	commit(name, payload) {
		this.mutations[name](this.state, payload)
	}

	dispatch(name, payload) {
		this.actions[name]({ state: this.state, commit: this.commit.bind(this), dispatch: this.dispatch.bind(this) }, payload)
	}
}

export default new Store()