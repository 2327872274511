import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_WAXIOS_URL

export default {
	async get(url: string, config?: any) {
        config = this._config(config)

		return axios.get(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(error))
	},

	async get_data(url: string, config?: any) {
		return axios.get(url, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(error.response))
	},

	async post(url: string, params = null, config?: any) {

		return axios.post(url, params, config)
			.then(response => response.data)
			.catch(error => Promise.reject(error))
	},

	async post_data(url: string, params = null, config?: any) {

		return axios.post(url, params, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(error.response))
	},

	async delete(url: string, config?: any) {
		return axios.delete(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(error))
	},

	async delete_data(url: string, config?: any) {
		return axios.delete(url, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(error.response))
	},

	async patch(url: string, params = null, config?: any) {
		return axios.patch(url, params, config)
			.then(response => response.data)
			.catch(error => Promise.reject(error))
	},

	async patch_data(url: string, params = null, config?: any) {
		return axios.patch(url, params, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(error.response))
	},

	async put(url: string, params = null, config?: any) {
		return axios.put(url, params, config)
			.then(response => response.data)
			.catch(error => Promise.reject(error))
	},

	async put_data(url: string, params = null, config?: any) {
		return axios.put(url, params, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(error.response))
	},

	_config(config: any) {
		return { params: { ...config } }
	}
}