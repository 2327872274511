<template>
	<div :class="`${step.customStyleKey}`" class="relative wrapper h-[calc(100%-85px)] w-full py-[32px] px-[32px] md:py-[40px] md:px-[66px] lg:px-[88px]" ref="wrapper">
		<h1 class="title text-[32px] font-semibold">{{ step.screen_title }}</h1>
		<p class="subtitle inter">{{ store.getters.getCurrentStepIndex() + 1 }} z {{ progressStepCount }} krokov</p>

		<div v-if="step?.items.length || !step.itemsLoaded" class="page-content grid grid-cols-2 xs:grid-cols-auto-fill-147px md:grid-cols-auto-fill-184px gap-0">
			<Z-option-card
				v-for="option in $skelet(step.items, 6)"
				:key="option?.id"
				:option="option"
				:active="step.value?.id === option?.id && !!step.value"
				:customStyleKey="step.customStyleKey"
				@selected="setSelectedOption(option)"
				@presentMoreInfoModal="presentMoreInfoModal"
				:ref="'option-card-' + option?.id"
			/>
		</div>

		<div v-if="(step?.items.length === 0 || !shownCards()) && step.itemsLoaded" class="w-full h-full flex justify-center items-center">
			<p class="inter text-[16px] md:text-[14px] text-[#172b3780] font-[500] relative bottom-[37px] relative bottom-[37px]">Nenašli sa žiadne možnosti.</p>
		</div>
		
		<div v-if="step?.value?.finish_enabled" class="flex gap-[12px] mt-[32px] max-w-[400px]">
			<A-toggle-input
				:value="step?.value?.finish_value"
				@input="onFinishInput"
			/>
			<div class="flex flex-col gap-[8px]">
				<h3 class="text-[#172b37e6] text-[14px] md:text-[16px] font-[600] leading-[130%]">Povrchová úprava skla</h3>
				<p class="inter text-[#172b3780] text-[12px] md:text-[14px] font-[500]">{{ step?.value?.finish_toggle_description }}sakldfjlkasf alskdjf laskfjalskdfjal skdfj alskdfjalsjfdl asdfla sldfk</p>
			</div>
		</div>

	</div>
</template>
  
<script>
import ZOptionCard from './_components/z-option-card.vue'

import ADetailModalContent from './_components/_modal-contents/a-detail-modal-content.vue'
import AToggleInput from '@/plugins/app@configurator/options-page/_components/option-card/_components/a-toggle-input.vue'
  
export default {
	inject: ['store', '$emitter', '$skelet'],

	components: {
		ZOptionCard,
		AToggleInput,
	},

    props: {
        step: {
            type: Object,
            required: true,
        },
		progressStepCount: {
			type: Number, 
			required: true
		}
    },

	methods: {
		setSelectedOption(option) {
			this.$emit('selected', option)
		},

        presentMoreInfoModal(option) {
            const modalData = {
                component: ADetailModalContent,
                componentData: option
            }            

            this.$emitter.emit('openModal', modalData)
        },

		onFinishInput(value) {
			this.$emit('finishInput', value)
		},

		shownCards() {
			return Object.values(this.$refs || {}).find(ref => ref[0]?.$refs['card'])
		}
	}
}
</script>
  
<style lang="sass" scoped>
.product
	.page-content
		@apply xs:grid-cols-auto-fill-147px md:grid-cols-auto-fill-292px

.variant
	::v-deep .card-texts
		@apply min-h-[101px] md:min-h-[108px] h-fit

	::v-deep .image-wrapper
		@apply h-[184px] md:h-[244px] min-h-[184px] md:min-h-[244px]
</style>