<template>
    <div>
        <div class="hidden xl:flex flex-col p-[24px] gap-[16px] w-[536px] rounded-[8px] border-[1px] border-solid border-[#172b3733] mt-[24px] float-right">
            <div class="flex self-end justify-end gap-[28px] text-[#172B37] text-[16px] font-[500] leading-[130%]">
                <p class="inline-block w-[110px] text-right">Základ DPH</p>
                <p class="inline-block w-[110px] text-right">Výška DPH</p>
            </div>
            <div class="inter flex flex-col gap-[8px] w-full">
                <div class="flex gap-[29px] w-full 'h-[20px]">
                    <div class="w-[210px] flex flex-col gap-[3px]">
                        <h3 class="font-[400] text-[16px] leading-[140%]">Produkty</h3>
                    </div>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.products_price }}</h4>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.products_price_vat }}</h4>
                </div>
                <div class="flex gap-[29px] w-full h-[20px]">
                    <div class="w-[210px] flex flex-col gap-[3px]">
                        <h3 class="font-[400] text-[16px] leading-[140%]">Montáž</h3>
                    </div>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.montage_price }}</h4>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.montage_price_vat }}</h4>
                </div>
                <div class="flex gap-[29px] w-full h-[43px]">
                    <div class="w-[210px] flex flex-col gap-[3px]">
                        <h3 class="font-[400] text-[16px] leading-[140%]">Doprava</h3>
                        <h4 class="text-[14px] font-[500] leading-[120%]">{{ offer.delivery_location }}</h4>
                    </div>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.delivery_price }}</h4>
                    <h4 class="inline-block w-[110px] text-[16px] font-[700] leading-[140%] text-right">{{ offer.delivery_price_vat || '230' }}</h4>
                </div>
            </div>

            <div class="h-[1px] w-full bg-[#172b3733] mt-[2px]"></div>

            <div class="flex gap-[29px] w-full h-[20px] inter">
                <h3 class="inline-block w-[210px] font-[700] text-[18px] leading-[140%]">Total</h3>
                <h4 class="inline-block w-[110px] text-[18px] font-[700] leading-[140%] text-right">{{ offer.total_price }}</h4>
                <h4 class="inline-block w-[110px] text-[18px] font-[700] leading-[140%] text-right">{{ offer.total_price_vat }}</h4>
            </div>
        </div>
        <div class="flex xl:hidden flex-col gap-[16px] xl:hidden mt-[32px]">
            <h2 class="text-[24px] font-[600] leading-[1]">Sumár</h2>

            <div class="flex flex-col p-[16px] gap-[16px] w-full rounded-[8px] border-[1px] border-solid border-[#172b3733]">
                <div class="flex flex-col w-full h-[40px]">
                    <h4 class="text-[16px] font-[400] leading-[140%]">Produkty (bez DPH)</h4>
                    <h4 class="text-[16px] font-[700] leading-[140%]">{{ offer.products_price }}</h4>
                </div>
                <div class="flex flex-col w-full h-[40px]">
                    <h4 class="text-[16px] font-[400] leading-[140%]">Montáž</h4>
                    <h4 class="text-[16px] font-[700] leading-[140%]">{{ offer.montage_price }}</h4>
                </div>
                <div class="flex flex-col w-full h-[63px]">
                    <h4 class="text-[16px] font-[400] leading-[140%]">Doprava</h4>
                    <h4 class="text-[16px] font-[700] leading-[140%]">{{ offer.delivery_price }}</h4>
                    <h4 class="text-[14px] font-[500] leading-[120%]">{{ offer.delivery_location }}</h4>
                </div>
                <div class="flex flex-col w-full h-[40px]">
                    <h4 class="text-[16px] font-[400] leading-[140%]">DPH</h4>
                    <h4 class="text-[16px] font-[700] leading-[140%]">{{ dph }}</h4>
                </div>

                <div class="h-[1px] w-full bg-[#172b3733] mt-[2px]"></div>

                <h3 class="font-[700] text-[18px] leading-[140%]">Total</h3>
                <h3 class="font-[700] text-[18px] leading-[140%]">{{ offer.total_price }}</h3>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        offer: {
            type: Object,
            required: true
        }
    },

    computed: {
        dph() {
            return parseFloat(this.offer.total_price_vat) - parseFloat(this.offer.total_price)
        }
    }
}
</script>