<template>
    <div class="w-full h-full pt-[6px] flex flex-col gap-[25px] items-center">
        <div class="w-full flex flex-col gap-[30px] items-center">
            <img :src="ModalCheckmarkCircle" alt="modal-checkmark-circle">
            <div class="w-full flex flex-col gap-[16px] items-center">
                <h2 class="text-[24px] md:text-[32px] text-[#1F2937] font-[600] leading-[1] text-center">Cenová ponuka bola odoslaná</h2>
                <p class="inter text-[14px] md:text-[16px] text-[#172b3780] font-[400] leading-[140%] text-center">Skontrolujte si vašu emailovú schránku.</p>
            </div>
        </div>
        <div class="w-full flex gap-[29px] justify-center items-center">
            <img @click="redirect('https://mail.google.com/')" :src="GmailLogo" alt="gmail-logo" class="cursor-pointer">
            <img @click="redirect('https://outlook.live.com/')" :src="OutlookLogo" alt="outlook-logo" class="cursor-pointer">
        </div>
    </div>
</template>

<script>
import ModalCheckmarkCircle from '../../../_assets/modal-checkmark-circle.svg'
import GmailLogo from '../../../_assets/gmail-logo.svg'
import OutlookLogo from '../../../_assets/outlook-logo.svg'

export default {
    inject: ['store', '$emitter'],
    
    data() {
        return {
            ModalCheckmarkCircle,
            GmailLogo,
            OutlookLogo
        }
    },

    methods: {
        redirect(url) {
            window.open(url)
        }
    }
}
</script>