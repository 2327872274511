<template>
    <div class="form bg-[#F4F4F4] rounded-[16px] w-full lg:w-[400px] xl:w-[500px] shrink-0 p-[40px]">
        <h3 class="text-[#172B37] text-[24px] font-[600] leading-none">{{ title }}</h3>
        <div v-if="inputs.length || !itemsLoaded" class="my-[40px]" :class="{'mb-0': !textNote}">
            <A-input
                v-for="input in $skelet(inputs, 2)"
                :key="input?.id"
                :input="input"
                :value="input?.value"
                :emptyValidation="emptyValidation"
                @input="onInput(input)"
                :ref="input?.id"
            />
            <div v-if="step?.glass_amount_enabled">
                <label class="text-[12px] font-[500] uppercase block mb-[8px] mt-[24px]">Množstvo Sklenených tabulí</label>
                <A-number-input
                    :minValue="1"
                    :maxValue="step?.glass_amount || null"
                    :value="step?.glass_amount_value"
                    @input="glassAmountInput"
                />
            </div>
        </div>

        <div v-else class="w-full h-full flex justify-center items-center my-[40px]">
			<p class="inter text-[16px] md:text-[14px] text-[#172b3780] font-[500]">Nenašli sa žiadne možnosti.</p>
		</div>

        <div v-if="textNote" class="w-full p-[16px] flex gap-[8px] bg-[#fff] items-start rounded-[8px]">
            <img :src="InformationCircleImage" alt="information-circle">
            <div class="flex flex-col gap-[8px] w-full">
                <h4 class="text-[#172B37] text-[14px] font-[600]">Textová poznámka</h4>
                <p v-if="textNote" class="inter text-[#172b3780] text-[12px] font-[500] leading-[1.4]">{{ textNote }}</p>
                <div v-else class="skelet w-[99%] h-[50px] rounded-[5px]"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AInput from './_components/a-input.vue'
import ANumberInput from '@/plugins/app@configurator/options-page/_components/option-card/_components/a-number-input.vue'

import InformationCircleImage from '../../_assets/information-circle.svg'

export default {
    inject: ['store', '$skelet'],
    
    components: {
        AInput,
        ANumberInput
    },

    props: {
        step: {
            type: Object,
            required: true,
        },
        inputs: {
            type: Array,
            required: true
        },
        textNote: {
            type: String,
        },
        itemsLoaded: {
            type: Boolean,
            default: false
        },
        emptyValidation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            InformationCircleImage
        }
    },

    computed: {
        title() {
            const variant = this.store.getters['getSteps']()[1]?.value
            return variant?.title
        }
    },

    methods: {
        onInput(option) {
            const ref = this.$refs[option?.id][0].$refs[`input-${option?.id}`]
            const value = ref.value

            this.$emit('input', { option, value })
        },

        glassAmountInput(value) {
            this.$emit('glassAmountInput', value)
        }
    }
}
</script>

<style lang="sass" scoped>

</style>