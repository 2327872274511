import { render, staticRenderFns } from "./a-confirm-step-toast.vue?vue&type=template&id=6b8cc674&scoped=true&"
import script from "./a-confirm-step-toast.vue?vue&type=script&lang=js&"
export * from "./a-confirm-step-toast.vue?vue&type=script&lang=js&"
import style0 from "./a-confirm-step-toast.vue?vue&type=style&index=0&id=6b8cc674&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8cc674",
  null
  
)

export default component.exports