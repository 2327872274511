<template>
    <div class="mx-[-8px] my-[-24px] md:mx-[-56px] md:my-[-56px] flex flex-col md:flex-row gap-[24px] md:gap-[48px] justify-between items-center">
        <div class="w-full h-auto max-w-auto md:max-w-[450px] md:h-[390px] rounded-[16px] overflow-hidden flex items-center max-h-[400px] md:max-h-auto">
            <img class="h-auto md:h-full w-full object-cover" :src="data.modal_image?.data.publicPath || data.cover_image?.data.publicPath" alt="variant-image">
        </div>
        <div class="w-full md:w-fit flex flex-col gap-[16px] px-[8px] pb-[16px] md:px-0 md:pb-0 h-auto md:h-[309px] overflow-auto md:min-w-[356px] md:pr-[16px]">
            <p class="text-[12px] md:text-[16px] text-[#527E99] uppercase leading-[130%] font-[500]">{{ categoryTitle }}</p>
            <h2 class="text-[24px] md:text-[32px] text-[#1F2937] font-[600] leading-[1]">{{ data.title }}</h2>
            <p class="inter text-[14px] md:text-[16px] text-[#172b37e6] font-[400] leading-[140%]">{{ data.modal_description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['store'],

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        categoryTitle() {
            const productTitle = this.store.getters['getSteps']()[0]?.value.title
            return this.data.category_title || productTitle
        }
    }
}
</script>