<template>
    <div class="absolute right-[-8px] md:right-[27px] lg:right-[48px] top-[20px] md:top-[23px] w-fit h-fit" :class="{'opacity-0': hidden, 'transition-opacity': !hidden }">
        <div class="z-[1000] flex">
            <div @mouseleave="onMouseLeave()" class="absolute flex w-auto h-[72px] right-[40px] z-[99999] mr-[56px] md:mr-[278px]">
                <div v-for="(variant, index) in variantList" :key="`hover${index}`" @mouseenter="onMouseEnter(index)" class="h-full w-[16px] z-[10000]"></div>
            </div>
            <div v-for="(variant, index) in variantList" :key="index" class="relative flex gap-[16px] w-[72px] h-[72px] md:w-[294px] border-[4px] border-solid border-[#F4F4F4] overflow-hidden rounded-full right-[40px] bg-[#F4F4F4] z-0 ml-[-56px] md:ml-[-278px]" :class="{ 'shadow-r-sm': index !== variantList.length - 1, 'z-[5999]': hoveredIndex === index}">
                <div class="h-[64px] w-[64px] h-full flex justify-center items-center bg-[#527E9999] overflow-hidden rounded-full">
                    <img class="w-full h-full object-cover opacity-[0.5]" :src="variant?.cover_image?.data.publicPath" alt="step-image">
                    <img class="absolute m-auto" :src="CheckmarkIcon" alt="checkmark-icon">
                </div>
                <div class="w-[192px] hidden md:flex h-full flex justify-start items-center mr-[16px]">
                    <h3 class="text-ellipsis text-[18px] text-[#172B37E5] font-[600] leading-[22px]">{{ variant.title }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckmarkIcon from '../_assets/checkmark-icon.svg'

export default {
    inject: ['store', '$emitter'],

    data() {
        return {
            CheckmarkIcon,

            hoveredIndex: null,
        }
    },

    computed: {
        variantList() {
            const items = this.store.getters['getItems']()
            const variantList = items.map(item => item.steps[1].value)
            if (this.store.state.editing) variantList.pop()
            return variantList
        },

        hidden() {
            const items = this.store.getters['getSteps']()
            const currentStepIndex = this.store.getters['getCurrentStepIndex']()
            const steps = this.store.getters['getSteps']()
            const currentStep = steps[currentStepIndex]

            return items.length === 0 || currentStep?.screen_type === 'summary'
        }
    },

    methods: {
        onMouseEnter(index) {
            this.hoveredIndex = index
        },

        onMouseLeave() {
            this.hoveredIndex = null
        }
    }
}
</script>

<style lang="sass" scoped>
.text-ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    padding-top: 2px
    padding-bottom: -1px
</style>e