<template>
    <div v-show="shown" @click.stop="closeModal()" class="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40 bg-[#172b37e6] px-[32px]">
        <div class="relative">
            <div @click.stop :class="{'w-full md:w-[616px]': !modalData.component, 'w-fit': modalData.component}" class="overflow-y-auto relative flex flex-col py-[32px] px-[16px] md:py-[64px] md:px-[64px] gap-[24px] md:gap-[32px] bg-[#fff] rounded-[16px] items-center z-50 max-h-[70vh]">
                <component v-if="modalData?.component" :is="modalData.component" :data="modalData?.componentData"/>
                <div v-else class="w-full h-full flex flex-col gap-[24px] md:gap-[32px] items-center">
                    <div class="w-full flex flex-col items-center gap-[16px]">
                        <h2 class="text-[24px] md:text-[32px] text-[#1F2937] font-[600] leading-[1] text-center">{{ modalData?.title }}</h2>
                        <p class="inter text-[14px] md:text-[16px] text-[#172b3780] font-[400] leading-[140%] text-center">{{ modalData?.message }}</p>
                    </div>
                    <div v-show="modalData?.email" class="w-full flex flex-col items-start gap-[8px] px-0 md:px-[52px]">
                        <p class="text-[#172b3780] text-[12px] font-[500] leading-[130%]">E-mail</p>
                        <input @keydown.enter="confirm()" v-model="email" class="inter w-full p-[16px] text-[16px] font-[400] leading-[140%] border-solid border-[1px] border-[#172b3733] rounded-[8px]" placeholder="Váš e-mail" type="email">
                    </div>
                    <div v-if="modalData?.customButtonText" class="w-full flex justify-center items-center">
                        <button :class="{'opacity-70 pointer-events-none': email.length == 0}" @click="confirm()" class="rounded-full py-[16px] px-[32px] flex justify-center items-center bg-[#527E99] text-[#fff] max-w-[212px] w-full">{{ modalData?.customButtonText }}</button>
                    </div>
                    <div v-else class="w-full flex flex-col md:flex-row-reverse justify-center items-center gap-[8px]">
                        <button @click="confirm()" class="rounded-full py-[16px] px-[32px] flex justify-center items-center bg-[#D73B19] text-[#fff] max-w-[212px] w-full">{{ modalData?.confirmText }}</button>
                        <button @click="closeModal()" class="rounded-full py-[16px] px-[32px] flex justify-center items-center bg-[transparent] text-[#527E99] border-solid border-[1px] border-[#527E99] max-w-[212px] w-full">NIE</button>
                    </div>
                </div>
                <img @click="closeModal()" :src="CloseIconBlack" alt="close-icon" class="hidden md:flex absolute top-[16px] right-[16px] cursor-pointer select-none" />
        </div>
            <img @click="closeModal()" :src="CloseIconWhite" alt="close-icon" class="flex md:hidden absolute top-[-48px] right-[0px] cursor-pointer select-none" />
        </div>
    </div>
</template>

<script>
import CloseIconBlack from '../../_assets/close-icon-black.svg'
import CloseIconWhite from '../../_assets/close-icon-white.svg'

export default {
    inject: ['store', '$emitter'],

    data() {
        return {
            CloseIconBlack,
            CloseIconWhite,
            modalData: {},
            shown: false,
            email: ''
        }
    },

    mounted() {
        this.$emitter.on('openModal', this.openModal)
    },

    beforeDestroy() {
        this.$emitter.off('openModal', this.openModal)
    },

    methods: {
        openModal(modalData) {
            this.modalData = modalData

            this.shown = true
        },
    
        confirm() {
            this.closeModal()
            this.modalData.callback(this.modalData.callbackParams, this.email)
            this.email = ''
        },

        closeModal() {
            this.shown = false
        }
    }
}
</script>

<style lang="sass" scoped>

</style>