<template>
    <div class="flex flex-col w-full relative p-[16px] gap-[16px] border-b-[1px] border-solid border-[#172b3733] text-[14px] leading-[120%]">
        <div class="flex flex-col gap-[8px] inter">
            <h4 class="font-[700]">Položka</h4>
            <h4 class="font-[500]">{{ title }}</h4>
        </div>
        <div class="flex flex-col gap-[8px] inter">
            <h4 class="font-[700]">Rozmer (mm)</h4>
            <h4 class="font-[500]">{{ sizeText }}</h4>
        </div>
        <div class="flex flex-col gap-[8px] inter">
            <h4 class="font-[700]">Popis</h4>
            <h4 class="font-[500]">{{ description }}</h4>
        </div>
        <div class="flex flex-col gap-[8px]">
            <h4 class="font-[700] inter">Množstvo</h4>
            <A-number-input v-if="!viewMode" @input="setQuantity" :value="item.quantity" :minValue="1" />
            <h4 v-else class="font-[500] inter">{{ item.amount }}</h4>
        </div>
        <div v-if="viewMode" class="flex flex-col gap-[8px] inter">
            <h4 class="font-[700]">Cena (bez DPH)</h4>
            <h4 class="font-[500]">{{ item.item_price_formatted }}</h4>
        </div>
        <div v-if="viewMode" class="flex flex-col gap-[8px] inter">
            <h4 class="font-[700]">Cena (s DPH)</h4>
            <h4 class="font-[500]">{{ item.item_price_vat_formatted }}</h4>
        </div>
        <img v-show="showDeleteIcon && !viewMode" @click="deleteItem()" :src="TrashIcon" alt="trash-icon" class="absolute bottom-[22px] left-[138px] cursor-pointer">
    </div>
</template>

<script>
import ANumberInput from '@/plugins/app@configurator/options-page/_components/option-card/_components/a-number-input.vue'

import TrashIcon from '../../../_assets/trash-icon.svg'

export default {
    inject: ['store', '$emitter'],
    
    components: {
        ANumberInput
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        showDeleteIcon: {
            type: Boolean,
            default: true
        },
        viewMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            TrashIcon
        }
    },

    computed: {
        title() {
            if (!this.viewMode) return this.item.name
            else return this.item.title
        },

        sizeText() {
            if (!this.viewMode) return this.item.size

            let string = ''
            let i = 1
            while(Object.keys(this.item).includes(`size_${i}`)) {
                if (`${this.item[`size_${i}`]}` == 'null') {
                    break
                }
                string += `${this.item[`size_${i}`]}x`
                i++
            }
            return string.slice(0, -1)
        },

        description() {
            if (!this.viewMode) return this.item.description


            let string = ''
            for (let item of this.item.description) {
                string += `${item.title} ${item.item_title}, `
            }
            string = string.slice(0, -2)
            return string
        },
    },

    methods: {
        setQuantity(value) {
            const items = this.store.getters['getItems']()

            items.find(item => item.id === this.item.id).quantity = value

            this.store.commit('setValue', { items })
        },

        deleteItem() {
            this.$emit('deleteItem')
        }
    }
}
</script>