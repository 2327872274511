<template>
    <div @click="toggle" class="input bg-[#527E99] cursor-pointer flex h-[32px] w-[71px] items-center justify-center rounded-[70px]" :class="{'flex-row-reverse bg-[#fff] border-[1px] border-solid border-[#172b3733]': !value, 'border-[#527E99]': value}">
        <div class="w-[42px] h-full flex justify-center items-center">
            <p class="select-none text-[16px] font-[600] leading-[1]" :class="{'text-[#172b3780] ml-0 mr-[4px]': !value, 'text-[#fff] mr-0 ml-[4px]': value}">{{ value ? 'áno' : 'nie' }}</p>
        </div>
        <div class="w-[26px] h-[26px] rounded-full" :class="{'mr-[3px] ml-0 bg-[#fff]': value, 'ml-[3px] mr-0 bg-[#172b3733]': !value}"></div>
    </div>
</template>

<script>
export default {
    inject: ['store', '$emitter'],

    props: {
        value: {
            default: false
        }
    },

    methods: {
        toggle() {
            this.$emit('input', !this.value)
        }
    }
}
</script>