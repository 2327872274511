<template>
	<footer class="relative bottom-0" :class="{'isSummary h-[217px] md:h-[85px] md:absolute': isSummary, 'border-t-2 h-fit': !isSummary}">
		<div v-if="!isSummary" class="progress-lines w-full flex justify-evenly items-center absolute top-0">
			<div  v-for="(progressLine, index) in Array(progressStepCount)" :key="progressLine" class="progress-line" :class="{active: index === currentStepIndex}" :style="`width: calc( 100% / ${progressStepCount} - 8px)`"></div>
		</div>
		<div :class="{'flex-col-reverse md:flex-row pt-[24px] pb-[32px] md:pt-0 md:pb-0 h-full': isSummary, 'h-[85px]': !isSummary}" class="footer-content w-full flex gap-[16px] items-center justify-between bg-[#fff] px-[44px] md:px-[66px] lg:px-[88px]">
			<button @click="previousStep()" :disabled="backButtonDisabled" class="secondary-button" :class="{'w-full md:w-auto': isSummary, 'opacity-0 pointer-events-none': currentStepIndex === 0}">SPÄŤ</button>
			<div class="flex gap-[16px]" :class="{'w-full flex-col-reverse md:w-fit md:flex-row': isSummary}">
				<button v-if="isSummary" @click="createNewOffer()" class="secondary-button">VYTVORIŤ NOVÚ PONUKU</button>
				<button @click="!isSummary ? nextStep() : submitOffer()" :disabled="nextButtonDisabled" class="primary-button" :class="{'opacity-[0.7]': nextButtonDisabled}">{{ nextButtonText }}</button>
			</div>
		</div>
	</footer>
</template>

<script>
import APriceOfferSentModalContent from '@/plugins/app@configurator/summary/_components/modal/_contents/a-price-offer-sent-modal-content.vue'

import axios from '@/plugins/app/_config/axios'

const api = {
	sendOffer: async (offer) => await axios.post_data('/offer', offer)
}

export default {
	inject: ['store', '$emitter'],

	props: {
		steps: {
			type: Array,
			required: true
		},
		activeStep: {
			type: Object,
			required: true
		},
		progressStepCount: {
			type: Number,
			required: true,
		}
	},

	computed: {
		currentStepIndex() {
			return this.store.getters.getCurrentStepIndex()
		},

		nextButtonText() {
			if (this.isSummary) return 'VYPRACOVAŤ'
			// ma to byt pre sumarom nie na sumary
			return this.activeStep.screen_type === 'summary' ? 'SUMÁR' : 'POKRAČOVAŤ'
		},

		isSummary() {
			return this.activeStep.screen_type === 'summary'
		},

		nextButtonDisabled() {
			if (this.activeStep?.screen_type === 'options' || this.activeStep?.screen_type === 'summary') return false
			
			const selectValue = this.activeStep.value

			if (this.activeStep?.screen_type?.startsWith('inputs')) {
				if (!this.activeStep.items?.length === 0) return true
				return false
			}
			
			return selectValue === null
		},

		backButtonDisabled() {
			const isEditing = this.store.state.editing
			const isFirstStep = this.store.getters['getCurrentStepIndex']() === 0
			const isFirstItem = this.store.getters['getItems']().length === 0
			return isFirstStep && (isEditing || isFirstItem)
		}
	},

	methods: {
		previousStep() {
			const isFirstStep = this.store.getters['getCurrentStepIndex']() === 0
			const isFirstItem = this.store.getters['getItems']().length === 0

			if (this.isSummary) this.store.commit('setValue', { editing: true })

			if (isFirstStep && !isFirstItem) return this.returnToSummary()
			
			this.store.commit('setCurrentStepIndex', this.currentStepIndex - 1)
		},

		nextStep() {
			// if on dimensions step, validate dimensions
			if (this.activeStep.screen_type.startsWith('inputs')) return this.$emitter.emit('validateDimensions', this.emitReGetSteps)

			const nextStepIndex = this.currentStepIndex + 1

			this.store.commit('setCurrentStepIndex', nextStepIndex)

			const willBeSummary = nextStepIndex === this.store.getters['getSteps']().length - 1

			if (willBeSummary) this.store.dispatch('submitItem')
			
			if (this.store.getters['getCurrentStepIndex']() === 2) this.$emit('getCurrentDynamicSteps')
		},

		returnToSummary() {
			this.store.editing = true

			// reset values to last item values
			const items = this.store.getters['getItems']()
			const item = items[items.length - 1]

			this.store.commit('setValue', { steps: [...item.steps] })
			
			this.$emit('reGetSteps')
			
			this.store.commit('setCurrentStepIndex', item.steps.length - 1)
			
		},

		emitReGetSteps() {
			// this.$emit('reGetSteps')
		},

		createNewOffer() {
			const modalData = {
				title: 'Chcete pokračovať?',
				message: 'Vaša cenová ponuka bude vymazaná a proces začnete odznova.',
				confirmText: 'Áno, pokračovať',
				callback: this.createNewOfferCallback
			}
			this.$emitter.emit('openModal', modalData)
		},

		createNewOfferCallback() {
			// reset all values
			this.store.commit('setValue', { items: [], product: null, location: null })
			this.store.commit('setCurrentStepIndex', 0)
			this.store.commit('setValue', { editing: false })
			localStorage.removeItem('zoskla_editing')
		},

		submitOffer() {
			const location = this.store.getters['getLocation']()
			if (!location?.name) return this.$emitter.emit('location-validation')

			const modalData = {
				title: 'Na akú adresu vám pošleme cenovú ponuku?',
				message: 'Cenovú ponuku vám odošleme obratom na nižšie uvedenú emailovú adresu',
				customButtonText: 'ODOSLAŤ',
				email: true,
				callback: this.sendSubmitOffer
			}
			
			this.$emitter.emit('openModal', modalData)
		},

		async sendSubmitOffer(callbackParams, email) {
			this.$emitter.emit('toggleLoader', true)

			try {
				const items = this.store.getters['getItems']()

				const body = {
					items: [],
					delivery_location: this.store.getters['getLocation']().formatted_address,
					email,
				}


				for (let item of items) {
					const itemData = {
						amount: item.quantity,
					}

					for (let step of item.steps) {
						if (step.glass_amount_enabled) itemData['glass_amount'] = step.glass_amount_value
						if (step.screen_type.startsWith('select')) {
							if (step.screen_type === 'select.variant') {
								itemData['variant_id'] = step.value.id
								continue
							}
							const key = Object.keys(step.value)[0]
							itemData[key] = step.value[key]
							if (Object.keys(step.value).includes('finish_enabled')) itemData['finish_enabled'] = step.value.finish_value
						}
						else if (step.screen_type === 'inputs') {
							for (let input of step.items) {
								itemData[`size_${input.id}`] = input.value
							}
						} else if (step.screen_type === 'options') {
							for (let option of step.items) {
								if (option.type === 'toggle') itemData['cutout'] = option.value
								else itemData['sockets'] = option.value
							}
						}
					}

					body.items.push(itemData)
				}

				await api.sendOffer(body)

				this.offerSubmitedSuccessFully()
			} catch (error) {
				console.error(error)
			} finally {
				this.$emitter.emit('toggleLoader', false)
			}
		},

		offerSubmitedSuccessFully() {
			const modalData = {
				component: APriceOfferSentModalContent
			}

			this.$emitter.emit('openModal', modalData)
		}
	}
}
</script>

<style lang="sass" scoped>
footer
	width: 100%
	background: #fff
	box-shadow: 0px -5px 28px 0px rgba(0, 0, 0, 0.08)
	border: none

	&.isSummary
		box-shadow: none !important
		background: transparent !important

	.progress-lines
		.progress-line
			height: 4px
			width: calc( 100% / 7 - 8px)
			background: rgba(23, 43, 55, 0.20)

			&.active
				background: #527E99
	
	.footer-content
		button
			position: relative
			top: 2px

.primary-button, .secondary-button
	display: inline-flex
	padding: 16px 32px
	justify-content: center
	align-items: center
	gap: 10px
	border-radius: 50px
	height: 41px
	padding: 16px 32px
	box-sizing: border-box

.primary-button
	background: #527E99
	color: #fff

.secondary-button
	background: transparent
	border: 1px solid #527E99
	color: #527E99
</style>