<template>
    <div :class="`${step.customStyleKey}`" class="wrapper h-[calc(100%-85px)] w-full py-[32px] px-[32px] md:py-[40px] md:px-[66px] lg:px-[88px]" ref="wrapper">
        <h1 class="title text-[32px] font-semibold">{{ step.screen_title }}</h1>
        <p class="inter subtitle">{{ store.getters.getCurrentStepIndex() + 1 }} z {{ progressStepCount }} krokov</p>

        <div v-if="step?.items.length || !step.itemsLoaded" class="page-content flex flex-col gap-[32px]">
            <A-option-card
                v-for="option in $skelet(step.items, 2)"
                :key="option?.id"
                :option="option"
                :value="option?.value"
                @input="onInput"
            />
        </div>

        <div v-else class="w-full h-full flex justify-center items-center">
			<p class="inter text-[16px] md:text-[14px] text-[#172b3780] font-[500]">Nenašli sa žiadne možnosti.</p>
		</div>

	</div>
</template>

<script>
import AOptionCard from './_components/option-card/a-option-card.vue'

export default {
    inject: ['store', '$emitter', '$skelet'],
    
    components: {
        AOptionCard
    },

    props: {
        step: {
            type: Object,
            required: true,
        },
        progressStepCount: {
            type: Number,
            required: true
        }
    },

    methods: {
        onInput(data) {
            this.$emit('input', data)
        },
    }
}
</script>