<template>
    <div class="input">
        <label v-if="input?.title" class="text-[12px] font-[500] uppercase block mb-[8px] mt-[24px]">{{ input?.title }}</label>
        <div v-else class="skelet w-[50px] h-[18px] rounded-[5px] mt-[24px] mb-[8px]"></div>
        <div class="relative">
            <div :class="{'border-solid border-[1px] border-[#D73B19]': invalid}" class="rounded-[8px]">
                <input v-if="input" v-once type="text" class="inter p-[16px] text-[16px] rounded-[8px] w-full h-[54px]" :value="value" @change="onChange()" @input="filterNumbers()" :ref="`input-${input?.id}`" :placeholder="input.helper || 'Zadajte rozmer v mm'">
                <div v-else class="skelet rounded-[8px] w-full h-[54px]"></div>
            </div>
            <div v-show="infoShown" class="inter absolute w-[208px] p-[10px] rounded-[8px] bottom-[64px] right-[-10px] bg-[#fff]">
                {{ input?.tooltip }}
            </div>
            <img v-if="input" @mouseenter="infoShown = true" @mouseleave="infoShown = false" :src="informationCircleImage" alt="information-circle" class="absolute right-4 top-[15px] cursor-pointer" :class="{'hidden': !input?.tooltip_enabled}">
            <img v-if="input && invalid" :src="octagonWariningImage" alt="octagon-waring" class="absolute right-4 top-[15px] cursor-pointer">
            <img v-if="input" v-show="infoShown" :src="triangleImage" alt="triangle-image" class="absolute w-[36px] h-[22px] right-[11px] bottom-[56px]">
        </div>
        <label v-if="input" class="inter text-[#172b3780] text-[12px] block mt-[2px]">Min: {{ input?.size_min }}mm, Max: {{ input?.size_max }}mm</label>
        <div v-else class="skelet w-[40px] h-[15px] rounded-[5px] mt-[2px]"></div>
    </div>
</template>

<script>
import informationCircleImage from '../../../_assets/information-circle.svg'
import triangleImage from '../../../_assets/triangle.svg'
import octagonWariningImage from '../../../_assets/octagon-warning.svg'

export default {
    inject: ['store'],

    props: {
        input: {
            type: Object,
        },
        value: {
            type: String
        },
        emptyValidation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            informationCircleImage,
            triangleImage,
            octagonWariningImage,
            infoShown: false,
        }
    },

    computed: {
        invalid() {
            if (this.value === '' || this.value === null) return this.emptyValidation

            const intValue = parseInt(this.value)

            return intValue < this.input?.size_min || intValue > this.input?.size_max
        }
    },

    methods: {
        _validate() {
            const value = this.$refs[`input-${this.input?.id}`]?.value

            if (value === '') return this.invalid = false

            this.invalid = value < this.input?.size_min || value > this.input?.size_max
        },

        onChange() {
            this.$emit('input')
        },

        filterNumbers() {
            const input = this.$refs[`input-${this.input?.id}`]
            input.value = input?.value.replace(/[^0-9]/g, '')
        }
    }
}
</script>

<style lang="sass" scoped>
.invalid
    background: red !important
</style>