<template>
    <div class="input flex w-fit h-[32px] p-[8px] items-center gap-[12px] rounded-[70px] border-[1px] border-solid border-[#172b3733] bg-[#fff]">
        <img @click="setValue(-1)" :src="minusIcon" alt="minus-icon" class="cursor-pointer select-none" />
        <input @input="setInputValue()" @focus="selectText()" @change="setValue(null, $refs.input.value)" :value="showValue" :style="`width: ${showValue.toString().length}ch`" class="text-[#172B37] text-center font-[600] leading-[1] text-[16px] p-0 m-0 border-none outline-none" ref="input" />
        <img @click="setValue(1)" :src="plusIcon" alt="plus-icon" class="cursor-pointer select-none" />
    </div>
</template>

<script>
import minusIcon from '../../../_assets/minus-icon.svg'
import plusIcon from '../../../_assets/plus-icon.svg'

export default {
    inject: ['store', '$emitter'],
    
    props: {
        value: {
            type: Number,
            default: 0
        },

        minValue: {
            type: Number,
            default: 0
        },

        maxValue: {
            type: Number,
        }
    },

    data() {
        return {
            minusIcon,
            plusIcon,

            showValue: this.value
        }
    },

    methods: {
        setValue(addValue, absoluteValue = null) {
            if ((this.value === this.minValue && addValue === -1) || (this.value === this.maxValue && addValue === 1)) return
            if ((absoluteValue !== null && parseInt(absoluteValue) < this.minValue) || absoluteValue === '') absoluteValue = this.minValue

            const finalValue = absoluteValue !== null ? parseInt(absoluteValue) : this.value + addValue
            this.$emit('input', finalValue)
            this.showValue = finalValue
        },

        setInputValue() {
            const input = this.$refs[`input`]
            input.value = input.value.replace(/[^0-9]/g, '')

            const value = this.$refs.input.value

            this.showValue = value
        },

        selectText() {
            this.$refs.input.select()
        }
    }
}
</script>