<template>
    <div :class="{'grid-cols-2fr-1fr-2fr-1fr-1fr-1fr': viewMode, 'grid-cols-2fr-1fr-2fr-1fr': !viewMode}" class="w-full relative grid gap-[20px] p-[16px] justify-between items-center self-stretch border-t-[1px] border-t-solid border-t-[#172b3733] bg-[#fff]">
        <div class="flex gap-[15px] items-center justify-start">
            <img v-if="viewMode" class="w-[43px] h-auto" :src="item?.image.data.publicPath" alt="">
            <p class="inter">{{ title }}</p>
        </div>
        <p class="inter">{{ sizeText }}</p>
        <p class="inter">{{ description }}</p>
        <A-number-input v-if="!viewMode" @input="setQuantity" :value="item.quantity" :minValue="1" />
        <p class="inter" v-else>{{ item.amount }}</p>
        <p class="inter" v-if="viewMode">{{ item.item_price_formatted }}</p>
        <p class="inter" v-if="viewMode">{{ item.item_price_vat_formatted }}</p>
        <img v-show="showDeleteIcon && !viewMode" @click="deleteItem()" :src="TrashIcon" alt="trash-icon" class="absolute right-[16px] cursor-pointer">
    </div>
</template>

<script>
import ANumberInput from '@/plugins/app@configurator/options-page/_components/option-card/_components/a-number-input.vue'

import TrashIcon from '../../../_assets/trash-icon.svg'

export default {
    inject: ['store', '$emitter'],
    
    components: {
        ANumberInput
    },

    props: {
        item: {
            type: Object,
            required: true
        },

        showDeleteIcon: {
            type: Boolean,
            default: true
        },
        viewMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            TrashIcon
        }
    },

    computed: {
        title() {
            if (!this.viewMode) return this.item.name
            else return this.item.title
        },

        sizeText() {
            if (!this.viewMode) return this.item.size

            let string = ''
            let i = 1
            while(Object.keys(this.item).includes(`size_${i}`)) {
                if (`${this.item[`size_${i}`]}` == 'null') {
                    break
                }
                string += `${this.item[`size_${i}`]}x`
                i++
            }
            return string.slice(0, -1)
        },

        description() {
            if (!this.viewMode) return this.item.description


            let string = ''
            for (let item of this.item.description) {
                string += `${item.title} ${item.item_title}, `
            }
            string = string.slice(0, -2)
            return string
        },

        
    },

    methods: {
        setQuantity(value) {
            const items = this.store.getters['getItems']()

            items.find(item => item.id === this.item.id).quantity = value

            this.store.commit('setValue', { items })
        },

        deleteItem() {
            this.$emit('deleteItem')
        }
    }
}
</script>

<style lang="sass" scoped>

</style>