<template>
	<div :class="`${step.customStyleKey}`" class="wrapper h-[calc(100%-85px)] w-full py-[32px] px-[32px] md:py-[40px] md:px-[66px] lg:px-[88px]" ref="wrapper">
		<h1 v-if="step.screen_title" class="title text-[32px] font-semibold">{{ step.screen_title }}</h1>
        <div v-else class="skelet max-w-[100%] w-[400px] h-[40px]"></div>
		<p class="subtitle inter">{{ store.getters.getCurrentStepIndex() + 1 }} z {{ progressStepCount }} krokov</p>

        <div class="page-content flex flex-col-reverse lg:flex-row lg:flex-row justify-between">
            <A-input-form @input="onInput" @glassAmountInput="glassAmountInput" :step="step" :inputs="step.items" :itemsLoaded="step.itemsLoaded" :textNote="step.text_note" :emptyValidation="emptyValidation" />

            <div class="image-container rounded-[16px] border-[#172b3733] border-solid border-[1px] overflow-hidden flex justify-center items-center w-full">
                <img v-if="step.size_image" :src="step.size_image" class="" alt="dimensions-preview">
                <div v-else class="skelet w-full h-[400px] lg:h-full"></div>
            </div>
        </div>

	</div>
</template>

<script>
import AInputForm from './_components/inputs-form/a-inputs-form.vue'

export default {
    inject: ['store', '$emitter'],

    components: {
        AInputForm,
    },

    props: {
        step: {
            type: Object,
            required: true,
        },
        progressStepCount: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            emptyValidation: false
        }
    },

    created() {
        this.$emitter.on('validateDimensions', this.validateDimensions)
    },

    beforeDestroy() {
        this.$emitter.off('validateDimensions', this.validateDimensions)
    },

    methods: {
        onInput(data) {
            this.$emit('input', data)
        },

        glassAmountInput(value) {
            this.$emit('glassAmountInput', value)
        },

        showToast(message) {
            if (message) return this.$emitter.emit('show-validation-toast', message)

            message = ''
            const inputs = this.step.items

            for (let input of inputs) {
                if (input.value < input.size_min || input.value > input.size_max) {
                    message = `Hodnota ${input.title} musí byť zadaná v uvedenom rozsahu ${input.size_min}mm až ${input.size_max}mm`
                    break
                }
            }

            this.$emitter.emit('show-validation-toast', message)
        },

        validateDimensions(callback) {
            const inputs = this.step.items

            for (let input of inputs) {
                if (input.value.length === 0) {
                    this.emptyValidation = true
                    return this.showToast('Všetky polia musia byť vyplnené.')
                }
                else if (input.value < input.size_min || input.value > input.size_max)
                    return this.showToast()
            }

            const nextStepIndex = this.store.getters['getCurrentStepIndex']() + 1

            this.store.commit('setCurrentStepIndex', nextStepIndex)

            let title = ''
            for (let input of inputs) {
                title += `${input.title}: ${input.value}mm `
            }

            this.$emitter.emit('show-confirm-step-toast')

            const willBeSummary = nextStepIndex === this.store.getters['getSteps']().length - 1

			if (willBeSummary) this.store.dispatch('submitItem')

            callback()
        }
    }
}
</script>

<style lang="sass" scoped>
.image-container
    height: calc( 100vh - 85px - 128px - 58px )

@media screen and (max-width: 1024px)
    .image-container
        height: fit-content
</style>