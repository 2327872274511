<template>
    <div v-show="shown" @click.stop="closeModal()" class="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40 bg-[#172b37e6] px-[32px]">
        <div class="loader"></div>
    </div>
</template>

<script>


export default {
    inject: ['$emitter'],

    data() {
        return {
            shown: false
        }
    },

    mounted() {
        this.$emitter.on('toggleLoader', this.toggleLoader)
    },

    beforeDestroy() {
        this.$emitter.off('toggleLoader', this.toggleLoader)
    },

    methods: {
        toggleLoader(shown) {
            this.shown = shown
        }
    }
}
</script>

<style lang="sass" scoped>
.loader 
    height: 50px
    width: 50px
    border-radius: 50%
    border: 8px solid #f3f3f3
    border-top: 8px solid #172B37
    animation: spin 0.6s linear infinite

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
</style>