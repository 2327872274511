<template>
    <div class="inter input relative h-[55px] w-full md:w-[428px]">
        <input v-model="location.name" @input="searchPlaces()" @click.stop @focus="handleFocus()" list="location-datalist" :class="{'border-solid border-[1px] border-[#D73B19]': invalid}" class="p-[16px] pl-[50px] w-full rounded-[8px] border-solid border-[1px] border-[#172b3733] text-[16px] font-[400] leading-[140%] text-[#172B37] relative" placeholder="Začnite písať adresu" ref="places-input">
        <img :src="MapPin" class="absolute top-[16px] left-[16px]" alt="map-pin">
        <img v-if="invalid" :src="octagonWariningImage" alt="octagon-waring" class="absolute right-4 top-[15px] cursor-pointer">
        <div v-show="showDatalist" :class="{'bottom-[55px]': !datalistFits, 'top-[55px]': datalistFits}" class="datalist w-full absolute md:w-[428px] shadow-[0px_1px_15px_0px_rgba(0,0,0,0.10)] flex flex-col gap-[2px] bg-[#fff]" ref="datalist">
            <div v-for="suggestion in locationSuggestions" :key="suggestion.place_id" @click="selectSuggestion(suggestion)" class="w-full h-[80px] py-[8px] px-[16px] flex gap-[10px] justify-start items-center cursor-pointer hover:bg-[#172b370d] relative" ref="datalist">
                <img :src="MapPinGray" alt="map-pin" class="w-[24px] h-[24px]">
                <div class="flex flex-col w-full h-full justify-center gap-[3px]">
                    <h4 class="text-[16px] font-[400] leading-[140%] text-[#172B37]">{{ suggestion.name }}</h4>
                    <h4 class="text-[14px] font-[400] leading-[140%] text-[#222e36]">{{ suggestion.formatted_address }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapPin from '../_assets/map-pin.svg'
import MapPinGray from '../_assets/map-pin-gray.svg'
import octagonWariningImage from '@/plugins/app@configurator/inputs-page/_assets/octagon-warning.svg'

import axios from '@/plugins/app/_config/axios.ts'

const api = {
	getLocationSuggestions: async (query, user) => await axios.get(`/google-maps/find-place`, {query: query, location: `${user.latitude},${user.longitude}`, radius: 10000, inputtype: "textquery", language: "sk", }),
}

export default {
    inject: ['store'],

    props: {
        datalistFits: {
            type: Boolean,
            required: true
        },
        emptyValidation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            MapPin,
            MapPinGray,
            octagonWariningImage,

            inputFocused: false,

            user: {
                longitude: null,
                latitude: null
            },
            location: {
                name: '',
                place_id: null
            },
            locationSuggestions: [],

            searchCooldown: null,

            isMounted: false
        }
    },

    computed: {
        showDatalist() {
            return this.location.name?.length > 0 && this.locationSuggestions.length > 0 && this.inputFocused
        },

        invalid() {
            return this.emptyValidation && !this.location.name
        }
    },

    mounted() {
        this.isMounted = true
        navigator.geolocation.getCurrentPosition(this.setUserLocation)

        const storeLocation = this.store.getters['getLocation']()
        if (storeLocation) this.location = storeLocation
    },

    methods: {
        searchPlaces() {
            clearTimeout(this.searchCooldown)
			this.searchCooldown = setTimeout( async() => {
				if (this.location.name != '') {
					await api.getLocationSuggestions(this.location.name, this.user).then((response) => {
						this.locationSuggestions = response.results.splice(0, 4)
					})
				} else {
					this.locationSuggestions = []
					this.location = {name: '', place_id: null}
				}
			}, 300)
        },

        handleFocus() {
            this.inputFocused = true;
            setTimeout(() => document.body.addEventListener('click', this.handleBlur), 120)
        },

        handleBlur() {
            this.inputFocused = false;
            document.body.removeEventListener('click', this.handleBlur)
        },

        setUserLocation(position) {
            this.user.longitude = position.coords.longitude
            this.user.latitude = position.coords.latitude
        },

        selectSuggestion(suggestion) {
            this.location = { ...suggestion }
            this.store.commit('setValue', { location: this.location })
        }
    }
}
</script>