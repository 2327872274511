<template>
    <div v-show="!forceHidden" class="absolute top-0 right-0 w-full rounded-[0px] sm:top-[20px] sm:right-[88px] sm:w-[296px] sm:rounded-[16px] bg-[#FFF1EE] inline-flex items-start cursor-pointer p-[16px] gap-[10px]" :class="{'opacity-0 transition-opacity z-0': !isShown, 'z-50': isShown }">
        <div class="flex flex-col gap-[4px] text-[#D73B19] w-full">
            <p class="text-[16px] leading-[1] font-[600]">Hodnoty neboli zadané správne</p>
            <p class="inter text-[12px] font-[400] leading-[20px]">{{ message }}</p>
        </div>
        <img :src="closeIcon" alt="close-icon" @click="hide()">
    </div>
</template>

<script>
import closeIcon from '../_assets/close-icon.svg'

export default {
    inject: ['store', '$emitter'],

    data() {
        return {
            message: '',
            isShown: false,
            forceHidden: false,
            showTime: 3000,
            lastTimeout: null,

            closeIcon,
        }
    },

    mounted() {
        this.$emitter.on('show-validation-toast', this._show)
    },

    beforeDestroy() {
        this.$emitter.off('show-validation-toast', this._show)
    },

    methods: {
        _show(message) {
            this.message = message
            this.isShown = true
            this.forceHidden = false

            clearTimeout(this.lastTimeout)

            this.lastTimeout = setTimeout(() => {
                this.isShown = false
            }, this.showTime)
        },

        hide() {
            this.forceHidden = true
            this.isShown = false
            clearTimeout(this.lastTimeout)
        }
    }
}
</script>