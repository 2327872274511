<template>
  <div id="app" class="h-full">
      <AConfiguratorLayout />
  </div>
</template>

<script>
import AConfiguratorLayout from '../../app@configurator/_layout/configurator.layout.vue'

export default {
  components: {
      AConfiguratorLayout
  }
}
</script>