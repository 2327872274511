<template>
    <div v-if="showCard" class="card flex flex-col rounded-[16px] h-auto w-full" :class="{active: active}" @click="selectOption()" ref="card">
        <div class="select-indicator w-[16px] h-[16px] md:w-[24px] md:h-[24px] rounded-full relative top-[8px] left-[8px] md:top-[16px] md:left[16px] border-[4px] md:border-[8px]" :class="{'non-active': !active}"></div>

        <div class="image-wrapper w-full h-auto rounded-[16px] overflow-hidden bg-[#f4f4f4] h-[132px] md:h-[181px] flex items-center justify-center">
            <img v-if="option" :src="option?.cover_image?.data.publicPath" class="w-full h-full object-cover margin-auto" :alt="option?.title" ref="variant-image">
            <div v-else :class="{'h-full aspect-[unset]': isVariantCard}" class="skelet w-full aspect-[1.5]"></div>
        </div>

        <div class="card-texts flex flex-col justify-center items-center w-full min-h-[46px] min-md:h-[48px] text-[14px] md:text-[16px] px-[7px] p-[10px]" :class="{'h-[64px] md:h-[66px]': option?.subtitle}">
            <h3 v-if="option && isVariantCard" class="text-center text-[#172B37] text-[10px] md:text-[12px] uppercase font-[500]">{{ option.category_title || 'no category' }}</h3>
            <div v-else-if="isVariantCard" class="skelet w-[50%] h-[14px] mb-[8px] mt-[1px]"></div>
            <h3 v-if="option" class="text-center text-[#172B37] text-[14px] md:text-[16px] font-[600]">{{ option.title }}</h3>
            <div v-else :class="{'mb-[4px]': isVariantCard}" class="skelet w-[80%] h-[16px]"></div>
            <h3 v-if="option?.subtitle" class="inter text-[#172b3780] text-[12px] md:text-[14px] font-[500]">{{ option.subtitle }}</h3>
            <h3 v-if="isVariantCard && option?.modal_enabled" @click.stop="presentMoreInfoModal()" class="text-center text-[#527E99] text-[14px] md:text-[16px] text-center uppercase underline">VIAC INFO</h3>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['store'],

    props: {
        option: {
            type: Object,
        },
        active: {
            type: Boolean,
            default: false
        },
        customStyleKey: {
            type: String
        }
    },

    computed: {
        isVariantCard() {
            return this.customStyleKey === 'variant'
        },

        isGlassTypeCard() {
            if (!this.option) return false
            return Object.keys(this.option).includes('glass_id')
        },

        showCard() {
            if (!this.isGlassTypeCard) return true
            const steps = this.store.getters['getSteps']()
            const sizeStep = steps.find(step => step.screen_type === 'inputs')
            const inputs = sizeStep?.items
            const sizes = this.option.sizes
            for (let input of inputs) {
                const size = sizes.find(size => size.size === input.title)
                if (!size || parseFloat(input.value) > size.size_max || parseFloat(input.value) < size.size_min) return false
            }
            return true
        }
    },

    methods: {
        selectOption() {
            this.$emit('selected')
        },

        presentMoreInfoModal() {
            if (!this.option) return
            this.$emit('presentMoreInfoModal', this.option)
        }
    }
}
</script>

<style lang="sass" scoped>
.card
    position: relative
    overflow: hidden
    margin: 0 auto
    border: 1px solid rgba(7, 29, 73, 0.10)
    cursor: pointer
    box-sizing: border-box
    .select-indicator
        background: #fff
        position: absolute
        z-index: 1

        &.non-active
            border: 1px solid rgba(7, 29, 73, 0.20)

    h3
        // font-family: Raleway

    &.active
        outline: 2px solid #527E99
        outline-offset: -2px
        .select-indicator
            border-color: #527E99

.skelet
    border-radius: 5px
            
</style>