<template>
    <div class="wrapper h-full relative w-full py-[32px] px-[32px] md:py-[40px] md:px-[66px] lg:px-[88px]" ref="page">
        <div class="w-full h-full" v-if="offer?.id">
            <div class="flex flex-col justify-between gap-[8px] mb-[40px]">
                <h1 class="title text-[#172B37] text-[32px] lg:text-[48px] font-semibold inline leading-[1]">Vaša cenová ponuka</h1>
                <p class="inter text-[#172B37] text-[18px] leading-[1]">Číslo {{ offerId }}</p>
            </div>
    
            <p class="inter text-[#374151] text-[16px] font-[400] leading-[1] mb-[24px]">Cenová ponuka je len orientačná a môže sa líšiť po zameraní a doladení detailov.</p>
    
            <div style="gap: 0px !important" class="page-content w-full flex flex-col">
                <A-summary-table
                    :offerItems="offer.items || []"
                    :viewMode="true"
                />
    
                <A-price-table
                    class="mb-[32px] xl:mb-[40px]"
                    :offer="offer || []"
                />
    
                <!-- <A-people-list
                    class="mb-[32px] xl:mb-[40px]"
                    :people="offer.people"
                /> -->
            </div>
        </div>
        <div v-else-if="offerLoading" class="w-full h-full flex justify-center items-center">
            <div class="loader"></div>
        </div>
        <div v-else class="w-full h-full flex justify-center items-center">
            <p class="inter text-[16px] md:text-[14px] text-[#172b3780] font-[500]">Ponuka sa nenašla.</p>
        </div>
    </div>
</template>

<script>
import axios from '../../app/_config/axios'

// components
import ASummaryTable from '../summary/_components/summary-table/a-summary-table.vue'
import APriceTable from './_components/a-price-table.vue'
// import APeopleList from './_components/a-people-list.vue'

const api = {
    getOffer: async (offerId) => await axios.get_data(`offer/${offerId}`)
}

export default {
    components: {
        ASummaryTable,
        APriceTable,
        // APeopleList
    },

    data() {
        return {
            offer: {},
            offerLoading: true
        }
    },

    computed: {
        offerId() {
            const url = location.href
            const lastPart = url.split('/').pop()
            const offerId = lastPart.split('-')
            return offerId[offerId.length - 1]
        },
    },

    mounted() {
        this.getOffer()
    },

    methods: {
        async getOffer() {
            try {
                const data = await api.getOffer(this.offerId)
                
                this.offer = data[0]
            } catch (error) {
                console.error(error)
            } finally {
                this.offerLoading = false
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.loader 
    height: 50px
    width: 50px
    border-radius: 50%
    border: 8px solid #f3f3f3
    border-top: 8px solid #172B37
    animation: spin 0.6s linear infinite

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
</style>