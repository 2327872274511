<template>
	<div class="layout relative h-[100%]">
		<component
			@selected="setSelectedValue"
			@input="setInputValue"
			@glassAmountInput="glassAmountInput"
			@finishInput="onFinishInput"
			:is="activeStepComponent"
			:step="activeStep || {}"
			:progressStepCount="footerProgressStepCount"
			ref="screen"
		/>
		<Z-footer v-if="!isOfferView" @reGetSteps="reGetSteps()" @getCurrentDynamicSteps="getCurrentDynamicSteps()" :steps="steps" :activeStep="activeStep || {}" :progressStepCount="footerProgressStepCount" />
		<A-modal />
		<A-confirm-step-toast />
		<A-validation-toast ref="toast"/>
		<ALoader/>
	</div>
</template>

<script>
import store from '../../app/_config/store.js'
import axios from '../../app/_config/axios'

import $skelet from '../../app/_config/v-for-skelet.js'

import mitt from 'mitt'
const $emitter = mitt()

// pages
import SelectPage from '@/plugins/app@configurator/select-page/select-page.vue'
import InputsPage from '@/plugins/app@configurator/inputs-page/inputs-page.vue'
import OptionsPage from '@/plugins/app@configurator/options-page/options-page.vue'
import Summary from '@/plugins/app@configurator/summary/summary.vue'
import OfferView from '@/plugins/app@configurator/offer-view/offer-view.vue'

// components
import ZFooter from '@/plugins/app@configurator/_layout/_components/z-footer.vue'
import AModal from '@/plugins/app@configurator/summary/_components/modal/a-modal.vue'
import AConfirmStepToast from '@/plugins/app@configurator/_layout/_components/a-confirm-step-toast.vue'
import AValidationToast from '@/plugins/app@configurator/inputs-page/_components/a-toast.vue'
import ALoader from './_components/a-loader.vue'

const api = {
	getProjectOptions: async () => await axios.get_data(`categories`),
	getOptions: async (productId) => await axios.get_data(`categories/${productId}`),
	getDynamicSteps: async (variantId) => await axios.get_data(`products/${variantId}`)
}

export default {
	provide: {
		store,
		$emitter,
		$skelet,
		axios
	},

	components: {
		ZFooter,
		AModal,
		AConfirmStepToast,
		AValidationToast,
		ALoader,
	},

	data() {
		return {
			store,
			defaultSteps: [
				{
					screen_type: 'select.product',
					screen_title: 'Vitajte, na úvod si vyberte produkt',
					items: [],
					customStyleKey: 'product',
					value: null,
					itemsLoaded: false
				},
				{
					screen_type: 'select.variant',
					screen_title: 'Vyberte variant',
					items: [],
					customStyleKey: 'variant',
					value: null,
					itemsLoaded: false,
				}
			],
			dynamicSteps: [],

			dynamicComponents: {
				SelectPage,
				InputsPage,
				OptionsPage,
				Summary,
			},
		}
	},

	computed: {
		steps() {
			const summaryStep = {
				screen_type: 'summary',
				screen_title: 'Sumár',
			}

			const sizeStep = {
				id: 0,
				screen_type: 'inputs.loading',
				screen_title: 'Zadajte rozmery produktu',
				items: [],
				customStyleKey: 'temporarySize',
				itemsLoaded: false
			}

			const dynamicSteps = this.dynamicSteps.length ? this.dynamicSteps : [sizeStep]

			const skipFinish = dynamicSteps[1]?.value?.finish_enabled && !dynamicSteps[1]?.value?.finish_value

			const steps = [...this.defaultSteps, ...dynamicSteps, summaryStep]

			const filteredSteps = steps.filter(step => !(step?.items?.some(item => (Object.keys(item).includes('finish_type_id')) && skipFinish)))

			return filteredSteps
		},

		activeStep() {
			const currentStepIndex = this.store.getters['getCurrentStepIndex']()
			return this.steps[currentStepIndex]
		},

		isOfferView() {
			const url = location.href
			const lastPart = url.split('/').pop()
			if (lastPart.split('-').splice(0, 3).join('-') === 'vasa-cenova-ponuka') return true
			else return false
		},

		activeStepComponent() {
			if (this.isOfferView) return OfferView

			const type = this.activeStep?.screen_type || ''
			if (type.startsWith('select')) return this.dynamicComponents['SelectPage']
			if (type.startsWith('inputs')) return this.dynamicComponents['InputsPage']
			if (type.startsWith('options')) return this.dynamicComponents['OptionsPage']
			if (type.startsWith('summary')) return this.dynamicComponents['Summary']
			else return null
		},

		footerProgressStepCount() {
			if (!this.dynamicSteps.length) return 7
			else return this.steps.length
		}
	},

	mounted() {
		const steps = this.store.getters['getSteps']()
		const currentStepIndex = this.store.getters['getCurrentStepIndex']()
		if (steps.length) {
			this.reGetSteps()
			if (!this.dynamicSteps.length && currentStepIndex > 1) this.getCurrentDynamicSteps()
		} else if (!this.store.getters['getItems']().length) {
			const isDefaultStep = currentStepIndex < 2
			if (isDefaultStep) this.getCurrentDefaultOptions()
			else if (!this.dynamicSteps.length) this.getCurrentDynamicSteps()
		}
	},

	methods: {
		// data & requests
		async getOptions(apiCall, stepIndex) {
			try {
				const productId = this.defaultSteps[0].value?.id
				const options = await apiCall(productId)

				if (stepIndex === 1) this.options = this.setCategoryTitleIfNotSet(options)

				this.steps[stepIndex].items = options

				this.steps[stepIndex].itemsLoaded = true

				this.store.commit('setSteps', this.steps)
			} catch (error) {
				console.error(error)
			}
		},

		getCurrentDefaultOptions() {
			const fillIndex = this.defaultSteps.indexOf(this.activeStep)
			if (fillIndex === -1) return

			const apiCall = fillIndex === 0 ? api.getProjectOptions : api.getOptions

			this.getOptions(apiCall, fillIndex)
		},

		async getDynamicSteps() {
			try {
				const variantId = this.defaultSteps[1].value?.id
				const data = await api.getDynamicSteps(variantId)

				const steps = data[0].steps

				steps.forEach(step => {
					if (step.screen_type === 'inputs') step.glass_amount_value = 1
					if (step.screen_type === 'select') step.value = null
					step.items.forEach((item, index) => {
						item.id = index + 1
						if (step.screen_type === 'select') {
							if (item.finish_enabled) return item.finish_value = false
						}
						for (let item of step.items) {
							if (item.type === 'number') item.value = 0
							else if (item.type === 'toggle') item.value = false
							else item.value = ''
						}
					})

					step.itemsLoaded = true
				})

				this.dynamicSteps = steps

				store.commit('setSteps', this.steps)
			} catch (error) {
				console.error(error)
			}
		},

		getCurrentDynamicSteps() {
			if (this.dynamicSteps.length) return
			this.getDynamicSteps()
		},

		resetSteps() {
			this.dynamicSteps = []
			for (let step of this.defaultSteps) {
				if (step.screen_type.startsWith('select')) step.value = null
				if (step.screen_type.startsWith('input') || step.screen_type.startsWith('options')) {
					for (let option of step.items) {
						if (option.type === 'number') option.value = 0
						else if (option.type === 'toggle') option.value = false
						else option.value = ''
					}
				}
				if (this.defaultSteps.indexOf(step) > 0) {
					step.items = []
					step.itemsLoaded = false
				}
			}
			store.commit('setSteps', this.steps)
		},

		setCategoryTitleIfNotSet(options) {
			options.map(option => {
				if (option.category_title) return option
				const categoryTitleFromProductName = this.steps[0].value?.title
				option.category_title = categoryTitleFromProductName
				return option
			})
			return options
		},

		// selecting values
		setSelectedValue(value) {
			if (this.activeStep.screen_type.startsWith('select')) this.activeStep.value = value

			const index = this.steps.indexOf(this.activeStep)

			if (index < 2) {
				this.dynamicSteps = []
				if (index === 0) {
					this.defaultSteps[1].items = []
					this.defaultSteps[1].itemsLoaded = false
				}
			}

			store.commit('setSteps', this.steps)
		},

		setInputValue(data) {
			const { option, value } = data
			const index = this.steps.indexOf(this.activeStep)

			const stepOption = this.activeStep.items.find(stepOption => stepOption.id === option.id)

			const newOption = {
				...stepOption,
				value
			}

			this.steps[index].items = this.activeStep.items.map(option => {
				if (option.id === stepOption.id) return newOption
				return option
			})

			store.commit('setSteps', this.steps)
		},

		glassAmountInput(value) {
			this.activeStep.glass_amount_value = value
			store.commit('setSteps', this.steps)
		},

		onFinishInput(value) {
			this.activeStep.value.finish_value = value
			store.commit('setSteps', this.steps)
		},

		// routing
		reGetSteps() {
			const steps = this.store.getters['getSteps']()
			if (steps.length) {
				this.defaultSteps = [...steps].splice(0, 2)
				this.dynamicSteps = [...steps].splice(2, steps.length - 2 - 1).filter(step => step.customStyleKey !== 'temporarySize')
			}
		},

		scrollPageWrapperToTop() {
			const wrapper = this.$refs['screen'].$refs['wrapper']
			if (wrapper) wrapper.scrollTo(0, 0)
		}
	},

	watch: {
		'store.state.currentStepIndex'(newValue, oldValue) {
			this.scrollPageWrapperToTop()

			const oldStepType = this.steps[oldValue].screen_type
			if (oldStepType === 'summary' && !this.store.state.editing) this.resetSteps()

			store.commit('setSteps', this.steps)

			if (this.activeStep?.screen_type.startsWith('summary')) return
			const currentStepIndex = this.store.getters['getCurrentStepIndex']()
			if (currentStepIndex < 2) this.getCurrentDefaultOptions()
			else if (currentStepIndex >= 2) this.getCurrentDynamicSteps()
		}
	}
}
</script>

<style lang="sass">
@import '../../app/_theme/style.sass'
</style>