<template>
    <div class="wrapper h-auto md:h-[calc(100%-85px)] relative w-full py-[32px] px-[32px] md:py-[40px] md:px-[66px] lg:px-[88px]" ref="wrapper">
        <div class="flex justify-between">
            <h1 class="title text-[32px] font-semibold inline">Sumár</h1>
            <button @click="addItem()" class="z-10 rounded-full flex xl:hidden p-[16px] gap-[12px] w-fit border-[1px] border-solid border-[#172b3733] h-fit">
                <span class="text-[16px] font-[600] leading-[1]">PRIDAŤ POLOŽKU</span>
                <img :src="PlusIcon" alt="plus-icon">
            </button>
        </div>
        <p class="inter subtitle">{{ store.getters.getCurrentStepIndex() + 1 }} z {{ progressStepCount }} krokov</p>

        <div style="gap: 0px !important" class="page-content w-full flex flex-col">
            <A-summary-table
                @addItem="addItem()"
                @deleteItem="openDeleteModal"
                :items="items"
            />

            <div class="flex flex-col mt-[40px] md:mt-[23px] relative">
                <h3 class="text-[#172B37] text-[24px] font-[600] leading-[1] mb-[24px]">Doprava</h3>
                <p class="text-[12px] text-[#172b3780] font-[500] mb-[8px]">PRE CENU DOPRAVY VYPLŇTE MIESTO MONTÁŽE</p>
                <A-autocomplete-input :datalistFits="datalistFits" :emptyValidation="emptyValidation" ref="delivery-input" />
            </div>
        </div>
    </div>
</template>

<script>
import ASummaryTable from './_components/summary-table/a-summary-table.vue'
import AAutocompleteInput from './_components/a-autocomplete-input.vue'

import PlusIcon from './_assets/plus-icon.svg'

export default {
    inject: ['store', '$emitter'],

    components: {
        ASummaryTable,
        AAutocompleteInput
    },

    props: {
        progressStepCount: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            PlusIcon,
            isMounted: false,
            emptyValidation: false
        }
    },

    computed: {
        items() {
            return this.store.getters['getItems']()
        },

        datalistFits() {
            if (!this.isMounted) return true

            this.$refs['delivery-input'].locationSuggestions

            const input = this.$refs['delivery-input'].$refs['places-input']
            const wrapper = this.$refs['wrapper']

            var offsetFromTop = input.getBoundingClientRect().top + window.scrollY;

            const spaceRemaining = wrapper.clientHeight - offsetFromTop - input.clientHeight

            return this.$refs['delivery-input'].locationSuggestions.length * 80 <= spaceRemaining
        }
    },

    mounted() {
        this.isMounted = true
        this.$emitter.on('location-validation', this.showLocationValidationToast)
    },

    methods: {
        addItem() {
            this.store.commit('setValue', { steps: [] })
            this.store.commit('setCurrentStepIndex', 0)
            this.store.commit('setValue', { editing: false })
        },

        openDeleteModal(item) {
            const modalData = {
                title: 'Naozaj chcete vymazať položku?',
                message: 'Akcia je nevratná.',
                confirmText: 'Áno, vymazať',
                callback: this.deleteItem,
                callbackParams: item
            }
            this.$emitter.emit('openModal', modalData)
        },

        deleteItem(item) {
            const items = this.store.getters['getItems']()

            const startIndex = items.indexOf(item)

            items.splice(startIndex, 1)

            this.store.commit('setValue', { items })
        },

        showLocationValidationToast() {
            this.emptyValidation = true
            this.$emitter.emit('show-validation-toast', 'Vyberte miesto montáže.')
        }
    }

}
</script>